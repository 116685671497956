import React, { useState } from "react"
import PictureOverlay from "./picture-overlay"

export default function collagelistitem({ item }) {
  const [toggleOverlay, setToggleOverlay] = useState(false)

  const openDetailOverlay = () => {
    setToggleOverlay(!toggleOverlay)
  }

  return (
    <>
      {toggleOverlay && (
        <PictureOverlay
          item={item}
          toggleShow={openDetailOverlay}
          show={toggleOverlay}
        />
      )}
      <li
        className="col-xl-4 col-md-6 col-xs-10 c-kollage-list__list__item"
        onClick={openDetailOverlay}
      >
        <img src={item.image} className="c-kollage-list__list__item__image" />
        <p className="c-kollage-list__list__item__info">
          {item.title.substring(0, item.title.length - 8)}
        </p>
      </li>
    </>
  )
}
