const COLLAGE_URL =
  "https://admin.demokratipagar.se/wp-json/demokratipagar/collage"
//const COLLAGE_URL = `${ROOT_URL}/collage.json`

/**
 * {
  "class":"9D",
  "school":"Skolan"
  "data":{
    "text":"Lorem ipsum",
    "background":"image-x",
    "font":"Blah",
    ...
  },
  "image":"data:image/png;base64,..."
}
*/

export default class {
  postData = async args => {
    try {
      const response = await fetch(COLLAGE_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(args),
      })
      return response
    } catch (error) {
      console.log(error)
    }
  }

  getData = async page => {
    try {
      const response = await fetch(COLLAGE_URL + `?page=${page - 1}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
      const res = await response.json()
      return res
    } catch (error) {
      console.log(error)
    }
  }
}
