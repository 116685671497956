import React, { useEffect, useRef, useState } from "react"
import { Helmet } from "react-helmet"

import PageHeader from "../../../../components/page-header"
import Footer from "../../../../components/footer"
import Functions from "../../../../components/kollage/atoms/functions"
import ListItem from "../../../../components/collage-list-item"

import arrowRight from "../../../../images/icons/dp-icon-arrow-link.svg"
import arrowLeft from "../../../../images/icons/dp-icon-arrow-link-left.svg"

import "../../../../scss/site.scss"
//import StickyCta from "../../../../components/sticky-cta"

export default function index() {
  const [data, setData] = useState(null)
  const [page, setPage] = useState(1)
  const [pageCount, setpageCount] = useState(0)
  const [toggleOverlay, setToggleoverlay] = useState(false)
  const functionsKit = new Functions()

  useEffect(() => {
    getData()
  }, [page])

  const getData = async () => {
    window.scrollTo(0, 0)
    const data = await functionsKit.getData(page)
    setData(data.collages)
    setpageCount(data.meta.total_pages)
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Kollage Översikt | Demokrati Pågår </title>
        <link
          rel="canonical"
          href="https://www.demokratipagar.se/demokratiska-samtal/skapa-demokratiska-samtal/kollage-list"
        ></link>
        <meta property="og:site_name" content="Demokrati Pågår"></meta>
        <meta property="og:type" content="article"></meta>
        <meta
          property="og:url"
          content="https://www.demokratipagar.se/demokratiska-samtal/skapa-demokratiska-samtal/kollage-list"
        ></meta>
        <meta property="og:title" content="Kollage Översikt"></meta>
        <meta name="description" content="" />
        <script
          type="text/javascript"
          src="https://www.browsealoud.com/plus/scripts/3.1.0/ba.js"
          crossorigin="anonymous"
          integrity="sha256-VCrJcQdV3IbbIVjmUyF7DnCqBbWD1BcZ/1sda2KWeFc= sha384-k2OQFn+wNFrKjU9HiaHAcHlEvLbfsVfvOnpmKBGWVBrpmGaIleDNHnnCJO4z2Y2H sha512-gxDfysgvGhVPSHDTieJ/8AlcIEjFbF3MdUgZZL2M5GXXDdIXCcX0CpH7Dh6jsHLOLOjRzTFdXASWZtxO+eMgyQ=="
        ></script>
      </Helmet>
      <div className="c-body">
        <PageHeader />
        <div
          role="main"
          id="main"
          className="o-main container-fluid c-kollage-list"
        >
          {!data && (
            <div className="c-loader">
              <div className="c-loader__spinner"></div>
            </div>
          )}
          {data && (
            <ul className="c-kollage-list__list">
              {Object.entries(data).map((item, i) => (
                <ListItem item={item[1]} key={i} />
              ))}
            </ul>
          )}
          {data && (
            <nav
              role="navigation"
              aria-label="Pagination Navigation"
              className="c-kollage-list__pagination"
            >
              <ul>
                {page > 1 && (
                  <li
                    aria-label="Goto previous page"
                    onClick={() => {
                      setPage(page - 1)
                      setData(null)
                    }}
                  >
                    <img src={arrowLeft} />
                  </li>
                )}
                {pageCount &&
                  [...Array(pageCount)].map((x, i) => (
                    <li
                      key={i}
                      aria-current={page === i + 1 && "true"}
                      aria-label={
                        page === i + 1
                          ? "Current page, Page " + i + 1
                          : "Goto page " + i + 1
                      }
                      onClick={() => {
                        setPage(i + 1)
                        setData(null)
                      }}
                    >
                      <button
                        className={`c-kollage-list__pagination__button ${
                          page === i + 1 &&
                          "c-kollage-list__pagination__button--active"
                        }`}
                        key={i}
                      >
                        {i + 1}
                      </button>
                    </li>
                  ))}
                {page < pageCount && (
                  <li
                    aria-label="Goto next page"
                    onClick={() => {
                      setPage(page + 1)
                      setData(null)
                    }}
                  >
                    <img src={arrowRight} />
                  </li>
                )}
              </ul>
            </nav>
          )}
        </div>
        <Footer />
        {/*<StickyCta />*/}
      </div>
    </>
  )
}
